import { Announcement, State, EventInfo } from './Types';

const awsUrl = 'https://62jdic9ut2.execute-api.us-east-1.amazonaws.com/stable';

const playQueue: (() => Promise<void>)[] = [];
let audioPlaying = false;

const eventDataById: { [key: string]: string } = {
  333: '3x3x3 Cube',
  222: '2x2x2 Cube',
  444: '4x4x4 Cube',
  555: '5x5x5 Cube',
  666: '6x6x6 Cube',
  777: '7x7x7 Cube',
  '333bf': '3x3x3 Blindfolded',
  '333fm': '3x3x3 Fewest Moves',
  '333oh': '3x3x3 One-Handed',
  '333ft': '3x3x3 With Feet',
  minx: 'Megaminx',
  pyram: 'Pyraminx',
  clock: 'Clock',
  skewb: 'Skewb',
  sq1: 'Square-1',
  '444bf': '4x4x4 Blindfolded',
  '555bf': '5x5x5 Blindfolded',
  '333mbf': '3x3x3 Multi-Blind',
  magic: 'Magic',
  mmagic: 'Master Magic',
  '333mbo': '3x3x3 Multi-Blind Old Style',
  'other-breakfast': 'Breakfast',
  'other-lunch': 'Lunch',
  'other-dinner': 'Dinner',
  'other-awards': 'Awards',
  'other-tutorial': 'New competitor tutorial',
  'other-registration': 'Registration',
};

async function playAudio(): Promise<void> {
  if (!audioPlaying) {
    audioPlaying = true;
    while (playQueue.length !== 0) {
      const lastElement = playQueue.pop();
      if (lastElement !== undefined) {
        await lastElement();
        // console.log('playing an audio...');
      }
    }
    audioPlaying = false;
  }
}

function parseIntOrNull(item: object): number | null {
  if (item == null) {
    return null;
  }
  const parsed = parseInt(item.toString());
  return Number.isNaN(parsed) ? null : parsed;
}

function parseDateOrNull(item: object): Date | null {
  const parsed = new Date(item.toString());
  console.log(`parsed is ${parsed}`);
  if (item == null) {
    return null;
  }
  return Number.isNaN(parsed.getTime()) ? null : parsed;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function jsonToAnnouncement(json: any): Announcement | null {
  const parsed = {
    datetime: parseDateOrNull(json.datetime),
    callType: json.call_type as string,
    volunteerType: json.volunteer_type as string,
    attemptNum: parseIntOrNull(json.attempt_num),
    eventName: json.event_name as string,
    roundNum: parseIntOrNull(json.round_num),
    groupNum: parseIntOrNull(json.group_num),
    totalGroups: parseIntOrNull(json.total_groups),
    startTime: parseDateOrNull(json.start_time),
    endTime: parseDateOrNull(json.end_time),
    numVolunteers: parseIntOrNull(json.num_volunteers),
  };
  if (parsed.datetime == null
    || (parsed.callType !== 'event' && parsed.callType !== 'volunteer' && parsed.callType !== '')
    || (parsed.volunteerType !== 'scramblers' && parsed.volunteerType !== 'runners' && parsed.volunteerType !== 'judges' && parsed.volunteerType !== '')
    || parsed.eventName == null || parsed.eventName === ''
    || parsed.startTime == null
    || parsed.endTime == null
    || (parsed.callType === 'volunteer' && parsed.volunteerType == null)
    || (parsed.roundNum != null && (parsed.roundNum < 1 || parsed.roundNum > 4))
    || (parsed.groupNum != null && (parsed.groupNum < 1 || parsed.groupNum > 15))
    || (parsed.numVolunteers != null && (parsed.numVolunteers < 1 || parsed.numVolunteers > 12))
    || (parsed.attemptNum != null && (parsed.attemptNum < 1 || parsed.attemptNum > 3))) {
    return null;
  }
  return {
    datetime: parsed.datetime,
    callType: parsed.callType,
    volunteerType: parsed.volunteerType,
    attemptNum: parsed.attemptNum,
    eventName: parsed.eventName,
    roundNum: parsed.roundNum,
    groupNum: parsed.groupNum,
    totalGroups: parsed.totalGroups,
    startTime: parsed.startTime,
    endTime: parsed.endTime,
    numVolunteers: parsed.numVolunteers == null ? 'several' : parsed.numVolunteers,
  };
}

function getEventName(eventId: string): string {
  return eventDataById[eventId];
}

async function playFile(fileName: string): Promise<void> {
  const audio = new Audio(`/audio/${fileName}.mp3`);

  return new Promise<void>((resolve, reject): void => { // return a promise
    audio.preload = 'auto'; // intend to play through
    audio.autoplay = true; // autoplay when loaded
    audio.onerror = reject; // on error, reject
    // @ts-ignore
    audio.onended = resolve; // when done, resolve
  });
}

export function playVoiceAnnouncement(announcement: Announcement): void {
  if (announcement.callType === 'volunteer') {
    playQueue.push(async (): Promise<void> => {
      await playFile('we-need');
      await playFile(`${announcement.numVolunteers}-more`);
      // take of final 's' if singular
      const jobToCall = announcement.numVolunteers === 1
        ? announcement.volunteerType.slice(0, -1) : announcement.volunteerType;
      await playFile(jobToCall);
      if (announcement.volunteerType === 'scramblers') {
        await playFile('please-help-scramble');
      } else if (announcement.volunteerType === 'judges') {
        await playFile('how-to-judge');
      }
      await playFile('comp-smoothly');
    });
  } else if (announcement.callType === 'event') {
    const eventName = getEventName(announcement.eventName);
    if (announcement.eventName.includes('other-')) {
      playQueue.push(async (): Promise<void> => {
        if (eventName === 'Lunch') {
          await playFile('lunch-time');
        } else if (eventName === 'Breakfast') {
          await playFile('breakfast-time');
        } else if (eventName === 'Dinner') {
          await playFile('dinner-time');
        }
      });
    } else {
      playQueue.push(async (): Promise<void> => {
        await playFile('now-calling');
        if (announcement.groupNum != null) {
          await playFile(`group-${announcement.groupNum}`);
        } else if (announcement.attemptNum != null) {
          await playFile(`attempt-${announcement.attemptNum}`);
        } else {
          await playFile(`round-${announcement.roundNum}`);
        }
        await playFile(announcement.eventName);
        await playFile('bring-puzzles-up');
        if (announcement.groupNum != null && announcement.totalGroups === announcement.groupNum) {
          await playFile('final-call');
          // console.log(`This is the final call for ${eventName} round ${announcement.roundNum}`);
        }
      });
      // console.log(`We are now calling group ${announcement.groupNum}
      // of ${eventName} round ${announcement.roundNum}`);
    }
  }
  playAudio();
}

export async function getUpdates(chatId: number, lastDate: Date): Promise<Announcement[]> {
  console.log(`${awsUrl}/${chatId}/updates-since/${lastDate.toISOString()}`);
  const res: Response = await fetch(`${awsUrl}/${chatId}/updates-since/${lastDate.toISOString()}`);
  return (await res.json()).announcements.map(jsonToAnnouncement);
}

let lastDate: Date;
const compName = 'Quabbin Summer 2019';
let currEvent = {
  name: '???',
  startEndString: '???',
  code: '???',
  groupOrAttemptString: '???',
  timeCalled: '0',
  roundString: '',
};

export function getNormalTime(time: Date, twelveHour: boolean): string {
  const hours: string = twelveHour ? (time.getHours() % 12).toString() : time.getHours().toString();
  const minutes: string = time.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
}

function getCurrEvent(announcement: Announcement): EventInfo {
  if (announcement.callType !== 'event') {
    return currEvent;
  }
  let groupOrAttemptString = '';
  if (announcement.groupNum != null) {
    groupOrAttemptString = `Group ${announcement.groupNum}`;
    if (announcement.totalGroups) {
      groupOrAttemptString += ` of ${announcement.totalGroups}`;
    }
  } else if (announcement.attemptNum) {
    groupOrAttemptString = `Attempt ${announcement.attemptNum}`;
  }

  const startEndString = `${getNormalTime(announcement.startTime, false)}-${getNormalTime(announcement.endTime, false)}`;

  return {
    name: getEventName(announcement.eventName),
    code: announcement.eventName,
    startEndString,
    groupOrAttemptString,
    timeCalled: new Date().toISOString(),
    roundString: announcement.roundNum == null ? '' : `Round ${announcement.roundNum}`,
  };
}

async function getCompNotes(chatId: number): Promise<string> {
  const res: Response = await fetch(`${awsUrl}/${chatId}/notes`);
  return (await res.json()).content as string;
}

export async function loadInitialEvent(chatId: number): Promise<EventInfo> {
  const res: Response = await fetch(`${awsUrl}/${chatId}/current-event`);
  // this used to have await below
  const announcement = jsonToAnnouncement(await res.json());
  if (announcement == null) {
    return {
      name: '???',
      startEndString: '???',
      code: '???',
      groupOrAttemptString: '???',
      timeCalled: '0',
      roundString: '',
    };
  }
  return getCurrEvent(announcement);
}

export async function updateContinuously(chatId: number): Promise<State> {
  if (lastDate === undefined) {
    currEvent = await loadInitialEvent(chatId);
    lastDate = new Date();
    console.log('initial load');
  } else {
    const updates = await getUpdates(chatId, lastDate);
    updates.forEach((announcement): void => {
      playVoiceAnnouncement(announcement);
      if (announcement.datetime > lastDate) {
        console.log(`date is now ${announcement.datetime}`);
        lastDate = announcement.datetime;
      }
      currEvent = getCurrEvent(announcement);
    });
    // console.log('normal load');
  }

  return {
    currEvent,
    compName,
    notes: await getCompNotes(chatId),
    time: new Date(),
  };
}

import React, { ReactElement } from 'react';
import './App.css';
import { updateContinuously, getNormalTime } from './AnnouncementHelper';
import { State } from './Types';

class App extends React.Component<{}, State> {
  private helpText = `/call - Call the next group or event
  /volunteers - Call for volunteers. You will be asked what kind of volunteers you need and you can optionally say how many.
  /skip - Move to the next group or event without making an announcement
  /back - Move to the previous group or event, which can be useful if you need to call the event again for some reason
  /status - See the upcoming group or event and the following one. This lets you plan for the next group or event without having to check the schedule
  /notes - Whichever message you type after /notes will appear in the notes section in the display website. This is useful for WiFi information, bathroom information, etc
  /url - Get the url for the display website, which can be loaded on a projector for competitors
  /help - See instructions for this bot`;

  public constructor(props: {}) {
    super(props);
    this.state = {
      compName: '???',
      currEvent: {
        name: '???',
        startEndString: '???',
        code: '???',
        groupOrAttemptString: '???',
        timeCalled: '0',
        roundString: '',
      },
      time: new Date(),
      notes: '',
    };
  }

  public componentDidMount = async (): Promise<void> => {
    const chatId = parseInt(window.location.href.substring(window.location.href.lastIndexOf('/') + 1));
    this.setState(await updateContinuously(chatId));
    setInterval(async (): Promise<void> => {
      const update = await updateContinuously(chatId);
      this.setState(update);
    }, 1000);
  }

  public render = (): ReactElement => {
    const { currEvent } = this.state;
    if (window.location.href.endsWith('/')) {
      return (
        <div className="container">
          <h1>Welcome to the competition announcer</h1>
          <p>To get started, send a message to @CompAnnouncerBot on Telegram! Below are the commands you can use with the bot.</p>
          <div style={{ textAlign: 'left' }}>
            {this.helpText.split('\n').map((item: string, key): ReactElement => (
            // eslint-disable-next-line react/no-array-index-key
              <span key={key}>
                {item}
                <br />
              </span>
            ))}

          </div>
        </div>
      );
    }
    return (
      <div className="main-div">
        <div className="center-item">
          <h1 className="comp-name">
            <i className="fa fa-cube" />
            {' '}
            {this.state.compName}
            {' '}
          </h1>
          <p className="current-time">
Current time:
            {' '}
            {getNormalTime(this.state.time, false)}
          </p>
          <div>
            <h2 className="current-event">
Current Event:
              {' '}
              {currEvent.name}
&nbsp;
              <i className={`cubing-icon event-${currEvent.code}`} />
            </h2>
            <p className="current-round">{currEvent.roundString}</p>
            <p className="current-group">{currEvent.groupOrAttemptString}</p>
            <p className="event-time">{currEvent.startEndString}</p>
            {/* <p className="current-group">Cutoff: {curr_event.cutoff},
             Time limit: {curr_event.limit}</p> */}
            <div className="notes">
              <p>{this.state.notes}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// updateContinuously(109453490);

export default App;
